import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import { Title, Section, Box } from '../../components/Core';

import imgV1 from '../../assets/image/svg/honesty.svg';
import imgV2 from '../../assets/image/svg/velocity.svg';
import imgV3 from '../../assets/image/svg/value-2.svg';
import imgV4 from '../../assets/image/svg/ownership.svg';
import imgV5 from '../../assets/image/svg/value-5.svg';

const CardImage = styled.div`
    max-width: 60px;
    min-width: 60px;
    min-height: 60px;
    max-height: 60px;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    margin-bottom: 29px;
`;

const ValueCard = ({ valueIcon, title, children, ...rest }) => (
    <Box className="text-center" pt="15px" px="30px" borderRadius={10} mb={4} {...rest}>
        <CardImage>
            <img src={valueIcon} className="img-fluid" alt="" />
        </CardImage>
        <div className="text-center">
            <Title variant="card" fontSize="24px" letterSpacing={-0.75} my={1}>
                {title}
            </Title>
        </div>
    </Box>
);

const Values = () => (
    <>
        {/* <!-- Team section --> */}
        <Section className="position-relative pt-0">
            <Container>
                <Row className="justify-content-center">
                    <Col lg="6" className="text-center pb-3">
                        <div className="">
                            <Title>Our Values</Title>
                        </div>
                    </Col>
                </Row>
                <Row className="align-items-center justify-content-center">
                    <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
                        <ValueCard valueIcon={imgV5} title="Innovation">
                            INNOVATION
                        </ValueCard>
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
                        <ValueCard valueIcon={imgV1} title="Honesty">
                            Honesty
                        </ValueCard>
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
                        <ValueCard valueIcon={imgV2} title="Velocity">
                            Velocity
                        </ValueCard>
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
                        <ValueCard valueIcon={imgV3} title="Impact">
                            Impact
                        </ValueCard>
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3 mt-lg-4">
                        <ValueCard valueIcon={imgV4} title="Ownership">
                            Ownership
                        </ValueCard>
                    </Col>
                </Row>
            </Container>
        </Section>
    </>
);

export default Values;
