import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import { Section, Text } from '../../components/Core';
import aboutImage from '../../assets/image/jpeg/aboutus-updated.jpg';
import { device } from '../../utils';

const SectionStyled = styled(Section)`
    background-image: url(${aboutImage});
    background-size: cover;
    height: 320px;
    background-position: center;
    margin-top: 64px;
    @media ${device.lg} {
        height: 680px;
        margin-top: 76px;
        background-position: 0 28%;
    }
`;

const Hero = ({ title, children, ...rest }) => {
    return (
        <>
            <SectionStyled {...rest}>
                <Container>
                    <Row>
                        <Col>
                            <div>
                                {/* <Title variant="hero" className="mb-0 text-center">
                                    {title}
                                </Title> */}
                                <Text>{children}</Text>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </SectionStyled>
        </>
    );
};
export default Hero;
