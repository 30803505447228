import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import { Section, Title, Text } from '../../components/Core';
import { device } from '../../utils';

// import imgContent1 from '../../assets/image/png/dubizzle-group-map-updated.png';
// import imgContent2 from "../../assets/image/jpeg/easy-image-2-2.jpg";

const SectionStyled = styled(Section)`
    padding-bottom: 30px;
    padding-top: 30px;
    @media ${device.md} {
        padding-bottom: 70px;
        padding-top: 70px;
    }
`;
const Content = () => (
    <>
        <SectionStyled bg="primary">
            <Container>
                <Row className="justify-content-center">
                    <Col lg="3">
                        <Title variant="SectionTitle" className="mb-0 text-center" color="white">
                            About Us
                        </Title>
                    </Col>
                </Row>
            </Container>
        </SectionStyled>
        <Section className="pb-0">
            <Container>
                <Row className="justify-content-center pb-4">
                    <Col lg="12">
                        <Text>
                            Dubizzle Group owns and operates bespoke classifieds portals in emerging
                            market of online portals, primarily in the MENA, South Asia and
                            Southeast Asia regions.
                        </Text>
                        <Text className="mt-4">
                            The group's flagship ventures are spread over 10 countries. We are
                            leading providers of classified portals in several countries not only
                            limited to horizontal and vertical platforms but are also catering to
                            multiple development projects in Pakistan.
                        </Text>
                        <Text className="mt-4">
                            We provide digital platforms where businesses can work efficiently and
                            be widely accessible to users from around the world.
                        </Text>
                        <Text className="mt-4">
                            Dubizzle Labs is the tech arm of the group that is building scalable
                            solutions and handles millions of users across the globe. We have a tech
                            team of 500+ members across the globe.
                        </Text>
                        <Text className="mt-4">
                            We have various departments providing diverse opportunities in
                            front-end, backend and mobile apps development. Our core focus is on
                            quality engineering, adapting the latest technologies and working on new
                            cutting edge solutions. Dubizzle Labs takes pride in having a modern
                            product culture, where we instill leadership and innovation across all
                            our teams.
                        </Text>
                    </Col>
                </Row>
                {/* <Row className="mt-5">
                    <Col lg="12">
                        <ContentImg>
                            <img src={imgContent1} alt="" className="img-fluid" />
                        </ContentImg>
                    </Col>
                    <Col lg="8" sm="7" className="mb-4">
            <ContentImg>
              <img src={imgContent2} alt="" className="img-fluid" />
            </ContentImg>
          </Col>
                </Row> */}
            </Container>
        </Section>
    </>
);

export default Content;
